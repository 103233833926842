import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIDistributeAmazonGift = ({ location }) => {
  const title = "インスタントウィンでアマギフを景品に設定できる？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoTのキャンペーンでは、Amazonギフトをプレゼントとして配布できます。その他、独自のクーポンやQUOカードPayなども配布が可能です。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Amazonギフト券をキャンペーンの景品として配布できますか？
            </h1>
            <p className="qa-page__answer">はい、可能です。</p>
            <p className="qa-page__text">
              PARKLoTの機能を利用し、Amazonギフト券の配布を自動化することが可能です。当選者の抽選作業もシステムにより自動化できるため、一切手間がかかりません。
            </p>

            <div className="qa-page__detail-container">
              {/* <h2 className="qa-page__h2">  
              【法人向け】 Amazonギフト券をキャンペーンの景品として利用できない商品・サービスは下記です。
            </h2> */}
              <div>
                <h2 className="qa-page__h2">
                  【法人向け】
                  Amazonギフト券をキャンペーンの景品として利用できない商品・サービスは下記です。
                </h2>
                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>タバコ</li>
                    <li>ポルノ・アダルト系商品・サービス</li>
                    <li>非合法ドラッグ</li>
                    <li>出会い系サービス※１</li>
                    <li>ギャンブル※２</li>
                    <li>オンラインオークション</li>
                    <li>消費者金融※３</li>
                    <li>
                      デイリーディール・割引サービス業（共同購入クーポンサービスなど）
                    </li>
                    <li>ライブ配信サービス</li>
                    <li>仮想通貨</li>
                    <li>テレマーケティング</li>
                    <li>禁輸商品、あるいは禁輸国との取引</li>
                    <li>銃器類</li>
                    <li>宗教および政治活動</li>
                    <li>Amazon が非合法・不適切とみなす商品・サービス</li>
                  </ol>
                </div>
                <h3 className="qa-page__h3">
                  詳しくは法人向け
                  Amazonギフト券の利用に関するガイドラインをご確認ください。
                </h3>
                <p className="qa-page__text">
                  詳細についてはAmazonのサイトにてご確認ください。
                </p>
                <p className="qa-page__text">
                  【関連リンク】
                  <a
                    href="https://www.amazon.co.jp/b?ie=UTF8&node=4731316051"
                    class="information__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Amazonギフト券を利用できない商品・サービス】（2019 年 6
                    月現在）
                  </a>
                </p>
              </div>
              <div>
                <h2 className="qa-page__h2">その他のギフト券の配布について</h2>
                <p className="qa-page__text">
                  PARKLoTのサービスと組み合わせたAmazonギフト券の配布自動化については、オプションにて承っております。
                </p>
                <p className="qa-page__text">
                  Amazonギフト券以外の各種ギフトコード、クーポンや引換券の配布についても、直接、Twitterダイレクトメッセージ経由でユーザーに届けることができます。
                </p>
                <p className="qa-page__text">
                  PARKLoTを使うことで面倒なAmazonギフト券の配布作業を自動化できます。
                </p>
                <p className="qa-page__text">
                  詳細についてはお問い合わせください。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIDistributeAmazonGift
